import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link'
import { useRouter } from "next/router";
import { Store } from '../../store'
import Notifications from './header_buttons/Notifications'
import UserMenu from "./UserMenu";
import { useMutation } from 'graphql-hooks'
import { Button } from "@material-tailwind/react";
import { sendGAEvent } from '@next/third-parties/google'

export const SUBSCRIBE_MUTATION = `
  mutation subscribeNewsletter(
    $id: ID!,
  ) {
    subscribeNewsletter(input: {
        id: $id,
    } ) {
      subscription {
        status
        newsletter {
          id
        }
      }
    }
  }
`

export const HeaderButtons = ({ subscribed, post=false, id=null }) => {
  const { state, dispatch } = useContext(Store)
  const [subscribeNewsletter, { error, data, loading }]
    = useMutation(SUBSCRIBE_MUTATION)
  const router = useRouter();

  useEffect(() => {
    if (!data || !data?.subscribeNewsletter) return;

    sendGAEvent('event', 'newsletter_subscribed', { location: 'HeaderButtons' })

    router.push("/recommendations").then(() => window.scrollTo(0, 0));
  }, [data]);

  useEffect(() => {
    if (!error) return;

    router.push("/subscribe").then(() => window.scrollTo(0, 0));
  }, [error]);

  const clickSubscribe = () => {
    subscribeNewsletter({ variables: {
      id: id,
    } } )

    sendGAEvent('event', 'click_subscribe_button_1', {value: 'xyz' })
  }

  const subscribeButton = () => {
    if(data) {
      return (
        <Button
          disabled
          className="mr-3 w-24 py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-10"
        >
          購読中
        </Button>
      );
    }

    if(state.currentUser && !!id) {
      return (
        <>
          {post ? (
            <div whitespace-nowrap onClick={(e) => clickSubscribe()}>
              <Button className="mr-3 w-24 py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-10 hidden md:block">
                購読する
              </Button>
              <Button className="w-24 py-1 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-8 fixed top-2 right-28 z-10 md:hidden">
                購読する
              </Button>
            </div>
          ) : (
            <div
              className="whitespace-nowrap"
              onClick={(e) => clickSubscribe()}
            >
              <Button className="mr-3 w-24 py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-10">
                購読する
              </Button>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {post ? (
          <Link href={`/subscribe`}>
            <button className="mr-3 w-24 py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-10 hidden md:block">
              購読する
            </button>
            <button className="w-24 py-1 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-8 fixed top-2 right-28 z-10 md:hidden">
              購読する
            </button>
          </Link>
        ) : (
          <Link href={`/subscribe`}>
            <button className="mr-3 w-24 py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-10">
              購読する
            </button>
          </Link>
        )}
      </>
    );
  };

return (
  <>
    {!subscribed && subscribeButton()}
    {state.currentUser ? (
      <>
        <Notifications />
        <UserMenu />
      </>
    ) : (
      <Link href={`/login`} className="text-sm mr-6 whitespace-nowrap ">
        ログイン
      </Link>
    )}
  </>
);
}

export default HeaderButtons;

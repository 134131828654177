import React,
  { useState, useEffect, useRef, useContext }
from 'react';
import Link from 'next/link'
import axios from 'axios';
import { useRouter } from 'next/router'
import { Store } from '../../store'
import { Stripe, loadStripe } from '@stripe/stripe-js';
import Cookies from "js-cookie";
import { sendGAEvent } from '@next/third-parties/google'

export const PaidButton = ({ monthlyBulk, user}) => {
  const [submit, setSubmit] = useState(false);
  const [list, setList] = useState([]);
  const { state, dispatch } = useContext(Store)

  const router = useRouter()

  useEffect(() => {
    if(!monthlyBulk.price) return

    const l = []
    for(let p of monthlyBulk.posts) {
      if(p.paid) {
        l.push(
          <li className='flex my-3 items-center'>
            <Link href={`/posts/${p.id}`} className='hover:underline flex items-center max-w-full'>
              <div className='mt-1 mr-4 text-sm bg-gray-600 text-white px-2 rounded whitespace-nowrap h-fit'>
                {p.publishedAt.slice(5, 10).replace(/-/g, '月')}日
              </div>

              <div className='whitespace-nowrap overflow-hidden overflow-ellipsis text-sm md:text-base'>
              {p.title}
              </div>
            </Link>
          </li>
        )
      }
    }
    setList(l)
  }, [monthlyBulk]);

  const getStripe = () => {
    let stripePromise

    if (!stripePromise) {
      if(user.role === 'admin') {
        stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      } else {
        stripePromise = loadStripe(
          process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
          { stripeAccount: user.stripeAccount }
        );
      }
    }
    return stripePromise;
  };

  const onClickBacknumber = async () => {
    if(!state.currentUser || state.currentUser?.__typename === 'Subscriber') {
      if(process.env.NEXT_PUBLIC_DOMAIN === 'localhost') {
        Cookies.set("paid_url", window.location.href, { expires: 1 });
      } else {
        Cookies.set("paid_url", window.location.href, { expires: 1, domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
      }
      sendGAEvent(
        'event', 'click_backnumber_payment',
        { result: 'go_to_singup', location: window.location.pathname }
      )
      router.push(`/signup?message=needed`)
      return
    }

    try {
      const response = await axios.post(
        process.env.NEXT_PUBLIC_API + '/stripe/sessions',
        {
          cancel_url: window.location.href.replace('#paywall#paywall', '#paywall'),
          success_url: window.location.href,
          payable_type: 'MonthlyBulk',
          payable_id: monthlyBulk.id
        },
        {
          headers: {
            "Content-Type": "application/json",
            uid: Cookies.get("uid"),
            client: Cookies.get("client"),
            "access-token": Cookies.get("access-token"),
          }
        })

      const { data } = response

      const stripe = await getStripe();
      stripe.redirectToCheckout({
        sessionId: data.id
      }).then(function (result) {
        sendGAEvent(
          'event', 'click_backnumber_payment',
          { result: 'go_to_singup', location: window.location.pathname }
        )
      });
    } catch (e) {
      if(e.response.data.errors.includes('user_not_found')) {
        router.push('/login?message=session&password=true')
        return
      }
      setSubmit(false)
    }
  }

  if(list.length === 0) return


  return (
    <div className='px-4'>
    <div className="w-full max-w-lg mx-auto mt-10 mb-32 border py-10 px-4 md:p-10 hover:bg-gray-100">
      <div className="text-xl">
        {monthlyBulk.month.replace(/-/g, "年")}月のバックナンバーを購入する
      </div>
      <div className="mb-6">{monthlyBulk.price} 円</div>
          <div className='mb-4'>
            バックナンバーを購入すると以下の有料記事が閲覧できます。
          </div>
          <ul className='mb-6 list-none'>
            {list}
          </ul>
      {submit ? (
        <button
          disabled
          className="mt-1 md:mt-0 w-full py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-12"
        >
          <svg
            className="animate-spin mr-2 inline h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Loading...
        </button>
      ) : (
        <button
          onClick={(e) => {
            setSubmit(true);
            onClickBacknumber();
          }}
          className="mt-1 md:mt-0 w-full py-2 px-4 border-gray-500 bg-gray-500 text-white border rounded text-sm h-12"
        >
          購入手続きへ
        </button>
      )}
    </div>

    </div>
  );
}

export default PaidButton;
